.App_construction_image {
    height: 400px;
    width: 400px;
}

.App-intro {
    color: black;
    font-size: 300%;
    font-family: 'Times New Roman', Times, serif;
    font-weight: bold;
}